import { render, staticRenderFns } from "./VodCard.vue?vue&type=template&id=3e50cc64&"
import script from "./VodCard.vue?vue&type=script&lang=js&"
export * from "./VodCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports