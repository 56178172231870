<template>
  <div class="uk-container uk-container uk-margin-medium">
    <div class="uk-box-shadow-small uk-box-shadow-hover-xlarge uk-background-default">
      <a href="http://tagentv.cupsell.pl/">
        <img src="@/assets/img/promuj.png" alt="Promuj TAGEN.TV - sklep z gadżetami" title="Promuj TAGEN.TV - sklep z gadżetami" class="uk-padding" uk-img>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShopBaner",
};
</script>
